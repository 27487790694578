<template>
  <div>
    <!-- picF225268520164ACFC6BB05BBD2F5447D.jpg -->
    <!-- DICT download92724CDBA550E8FEE1F2624C6DB09BB3.swf -->
    <img class="rounded img-large" src="@/assets/news-network/challenge-monster.jpg">
    <p>
      Professor Brickkeeper proclaims "The Creation Lab is running low on inspiration! Help by making a monster-- and fast!!"
    </p>
    <p>
      You can overcome the latest creative challenge by building a LEGO beast that's scary, sweet or something else!  Leap over to the Creation Lab and <router-link :to="{ name: 'creation-lab-list', params: {challengeId: '089f32a8-e94f-4b89-b95d-7704af8e1eeb'} }">make your own LEGO Universe monster</router-link>!
    </p>
  </div>
</template>
